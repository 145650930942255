import VerifiedIcon from "@mui/icons-material/Verified";
import { Typography } from "@mui/material";
import { useSharedContext } from "../../SharedContext";
import ROUTES from "../../routes";
import Navigate from "../Common/Navigate";
import { useEffect } from "react";

export default function TopUpSuccess({ order_id, already_fulfilled }) {
  const { t, setCartItems } = useSharedContext();

  useEffect(() => {
    if (!already_fulfilled) {
      setCartItems([]);
      window.gtag("event", "conversion", {
        send_to: "AW-16914721984/W3H8CMjw1qcaEMDZyIE_",
        value: 1.0,
        currency: "ILS",
        transaction_id: order_id,
      });
    }
  }, []);

  return (
    <>
      <VerifiedIcon sx={{ fontSize: "7rem", color: "var(--online-color)" }} />
      <Typography align="center" sx={{ fontSize: "2.25rem" }}>
        {t("topupSucceeded")}
      </Typography>
      <Typography align={"center"} sx={{ fontSize: "1.25rem" }}>
        {t("orderNumber")}: <b>{order_id}</b>
        <br />
        {t("emailOrderConfirmation")}
        <br />
        {t("thankYou2")}
        <br />
        <Navigate to={ROUTES.HOME} withDecoration>
          {t("backToHomePage")}
        </Navigate>
      </Typography>
    </>
  );
}
