import * as React from "react";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Trans } from "react-i18next";
import { useEffect, useState } from "react";
import { useSharedContext } from "../../SharedContext";

export default function ProcessingRequestSpinner() {
  const [points, setPoints] = useState("");
  const { isRTL } = useSharedContext();

  const changePoints = () => {
    let i = 0;
    const pointsStates = ["", ".", "..", "..."];

    const intervalId = setInterval(() => {
      setPoints(pointsStates[i % 4]);
      i += 1;
    }, 500);

    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    const cleanup = changePoints();

    return cleanup;
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px", alignItems: "center" }}>
      <CircularProgress />
      <Typography sx={{ direction: isRTL ? "rtl" : "ltr" }} variant="h5">
        <Trans i18nKey="waitWhileProcessingRequest" components={{ br: <br /> }} />
        {points}
      </Typography>
    </Box>
  );
}
