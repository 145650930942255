import React from "react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSharedContext } from "../../SharedContext";

export default function CloseableModal({ open, setOpen, children, sx }) {
  const { isRTL } = useSharedContext();
  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "CALC(100% - 48px)",
          maxWidth: "500px",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          boxSizing: "border-box",
          px: { xs: 2, sm: 4 },
          paddingTop: { xs: 5, sm: 4 },
          paddingBottom: { xs: 2, sm: 4 },
          direction: isRTL ? "rtl" : "ltr",
          ...sx,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", top: 5, [isRTL ? "left" : "right"]: 8 }}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </Modal>
  );
}
