import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar/App";
import HomePage from "./components/HomePage/App";
import SimPackages from "./components/SimPackages/App";
import Contact from "./components/Contact/App";
import ROUTES from "./routes";
import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import AutoScrollToTop from "./components/Common/AutoScrollToTop";
import Cart from "./components/Cart/App";
import CartModal from "./components/Common/CartModal";
import { SharedContextProvider } from "./SharedContext";
import PageNotFound from "./components/Common/PageNotFound";
import { PopUp } from "./components/Common/PopUp";
import Footer from "./components/Footer/App";
import StaticPage from "./components/Footer/StaticPage";
import WhatsappWidget from "./components/Common/WhatsappWidget";
import { HelmetProvider } from "react-helmet-async";
import LanguageRouteHandler from "./LanguageRouteHandler";
import UnderDev from "./components/Common/UnderDev";
import WithHTMLHeaders from "./WithHTMLHeaders";
import OrderConfirmation from "./components/OrderConfirmation/App";
import Checkout from "./components/Checkout/App";

export default function App() {
  const [formValues, setFormValues] = React.useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    city: "",
    street: "",
    house: "",
    entrance: "",
    apartment: "",
    deliveryNotes: "",
    agreeToReceiveSmsCB: true,
  });

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Router>
          <SharedContextProvider>
            <Container sx={{ minHeight: "CALC(100vh + 50px)", position: "relative" }} maxWidth={false} disableGutters>
              <CartModal />
              <NavBar />
              {/* <UnderDev /> */}
              <PopUp sx={{ mt: "62px" }} />
              <Routes>
                <Route element={<LanguageRouteHandler />}>
                  <Route
                    path={ROUTES.HOME}
                    element={<WithHTMLHeaders component={<HomePage />} titleKey="homePage" />}
                  />
                  <Route
                    path={ROUTES.TOP_UP_PACKAGES}
                    element={<WithHTMLHeaders component={<SimPackages isTopUp={true} />} titleKey="simPackagesPage" />}
                  />
                  <Route
                    path={ROUTES.SIM_ORDER_PACKAGES}
                    element={<WithHTMLHeaders component={<SimPackages isTopUp={false} />} titleKey="simPurchasePage" />}
                  />
                  <Route
                    path={ROUTES.CONTACT}
                    element={<WithHTMLHeaders component={<Contact />} titleKey="contactUsPage" />}
                  />
                  <Route
                    path={ROUTES.CART}
                    element={
                      <WithHTMLHeaders
                        component={<Cart formValues={formValues} setFormValues={setFormValues} />}
                        titleKey="cartPage"
                      />
                    }
                  />
                  <Route
                    path={ROUTES.ACCESSIBILITY}
                    element={
                      <WithHTMLHeaders
                        component={<StaticPage page={"accessibilityStatement"} />}
                        titleKey="accessibilityStatementPage"
                      />
                    }
                  />
                  <Route
                    path={ROUTES.PRIVACY}
                    element={
                      <WithHTMLHeaders component={<StaticPage page={"privacyPolicy"} />} titleKey="privacyPolicyPage" />
                    }
                  />
                  <Route
                    path={ROUTES.TERMS}
                    element={
                      <WithHTMLHeaders component={<StaticPage page={"termsOfUse"} />} titleKey="termsOfUsePage" />
                    }
                  />
                  <Route
                    path={ROUTES.CHECKOUT}
                    element={<WithHTMLHeaders component={<Checkout />} titleKey="homePage" />}
                  />
                  <Route
                    path={ROUTES.ORDER_CONFIRMATION}
                    element={<WithHTMLHeaders component={<OrderConfirmation />} titleKey="homePage" />}
                  />
                  <Route path=":lang?/*" element={<PageNotFound />} />
                </Route>
              </Routes>
              <Footer />
              <WhatsappWidget />
            </Container>
            <AutoScrollToTop />
          </SharedContextProvider>
        </Router>
      </HelmetProvider>
    </ThemeProvider>
  );
}
