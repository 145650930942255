import { cloneElement, useState } from "react";
import { useSharedContext } from "../../../SharedContext";

export default function PhoneNumberValidator({ textField, button, package_id }) {
  const { t, setOpenCartModal, cartItems, setCartItems } = useSharedContext();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [validated, setValidated] = useState(false);

  const validatePhoneNumber = (number) => {
    const regex = /^(0|972|\+972)?5[0-9]{8}$/;
    return regex.test(number);
  };

  const handleChange = (e) => {
    const input = e.target.value;
    const cleanedInput = input.replace(/[^\d+]/g, "");
    setPhoneNumber(cleanedInput);
    if (validated && !validatePhoneNumber(cleanedInput)) {
      setError(true);
      setErrorMessage(t("phoneNumberNotValid"));
    } else {
      setError(false);
      setErrorMessage("");
    }
  };

  const handleSubmit = () => {
    setValidated(true);
    if (!validatePhoneNumber(phoneNumber)) {
      setError(true);
      setErrorMessage(t("phoneNumberNotValid"));
    } else {
      setError(false);
      setPhoneNumber("");
      setErrorMessage("");
      const cartItemsNoDup = [...cartItems].filter((x) => x.phone_number.slice(-9) !== phoneNumber.slice(-9));
      setCartItems([...cartItemsNoDup, { package_id: package_id, phone_number: phoneNumber }]);
      setOpenCartModal(true);
    }
  };

  return (
    <>
      {cloneElement(textField, {
        value: phoneNumber,
        onChange: handleChange,
        error: error,
        helperText: error && errorMessage,
        sx: {
          ...textField.props.sx,
          "& .MuiOutlinedInput-input": {
            padding: "10px",
            direction: "ltr",
          },
          "& .MuiInputLabel-root": {
            transform: "translate(10px, 9px) scale(1)",
          },
          "& .MuiInputLabel-shrink": {
            transform: "translate(15px, -9px) scale(0.75)",
          },
        },
      })}
      {cloneElement(button, { onClick: handleSubmit })}
    </>
  );
}
