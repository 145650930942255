const ROUTES = {
  HOME: "/:lang?",
  TOP_UP_PACKAGES: "/:lang/topup/packages/:operator_name",
  SIM_ORDER_PACKAGES: "/:lang/simorder/packages/:operator_name",
  CONTACT: "/:lang/contact",
  CART: "/:lang/cart",
  ACCESSIBILITY: "/:lang/accessibility",
  PRIVACY: "/:lang/privacy",
  TERMS: "/:lang/terms",
  CHECKOUT: "/:lang/checkout/:order_id",
  ORDER_CONFIRMATION: "/:lang/order-confirmation/:order_id",
};

export default ROUTES;
