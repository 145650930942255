import { Container, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import CenteredContainer from "../Common/CenteredContainer";
import SimCard from "../Common/SimCard/SimCard";
import PackageCardVertical from "../Common/PackageCard/PackageCardVertical";
import { forwardRef, useEffect, useState } from "react";
import SimModal from "./SimModal";
import { useSharedContext } from "../../SharedContext";
import LoadingSpinner from "../Common/LoadingSpinner";
import Recommended from "../Common/Recommended";
import API from "../../API";

function TopPackages({}, ref) {
  const { isLoading, setIsLoading, isRTL, t, i18n, operators } = useSharedContext();
  const [topPackages, setTopPackages] = useState();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState();
  const [logoBgColor, setLogoBgColor] = useState();

  const openModal = (name, logoBgColor) => {
    setName(name);
    setLogoBgColor(logoBgColor);
    setOpen(true);
  };

  const getTopPackages = async () => {
    setIsLoading(true);
    const top_packages = await API.getRecommendedPackages(i18n.language);
    if (top_packages != null) {
      setTopPackages(top_packages);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTopPackages();
  }, [i18n.language]);

  return (
    <Container sx={{ paddingY: "68px", direction: isRTL ? "rtl" : "ltr" }} ref={ref}>
      <SimModal open={open} setOpen={setOpen} name={name} logoBgColor={logoBgColor} />
      <Container sx={{ marginBottom: "48px" }} disableGutters>
        <Container sx={{ marginBottom: "30px", display: "flex", justifyContent: "center", flexDirection: "column" }}>
          <CenteredContainer style={{}}>
            <LocalFireDepartmentIcon
              fontSize="h4"
              sx={{
                [isRTL ? "marginLeft" : "marginRight"]: "5px",
              }}
            />
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {t("topPackagesButton")}
            </Typography>
          </CenteredContainer>
          <Typography variant="h6" textAlign="center" sx={{ color: "var(--red-color)" }}>
            {t("limitedSalePrices")}
          </Typography>
        </Container>
        {!isLoading && topPackages ? (
          <Container sx={{ display: "flex", flexWrap: "wrap", rowGap: "40px", columnGap: "20px" }} disableGutters>
            {topPackages.map((packageInfo) => {
              const matching_operator = operators.find((x) => x.name === packageInfo.operator_name);
              return (
                <Container key={packageInfo.id} sx={{ width: "fit-content", position: "relative" }} disableGutters>
                  {["partner", "012mobile"].includes(packageInfo.operator_name) && (
                    <Recommended text={t("recommended")} />
                  )}
                  <PackageCardVertical
                    title={
                      <Typography
                        sx={{
                          color: ["partner", "012mobile"].includes(packageInfo.operator_name) ? "black" : "white",
                          textAlign: "center",
                          fontSize: "1.4rem",
                        }}
                      >
                        <b>{t(packageInfo.operator_name)}</b> {t("top_selling")}
                      </Typography>
                    }
                    title_color={matching_operator.title_color}
                    package_info={packageInfo}
                    isTopUp={true}
                    isTopPackages={true}
                  ></PackageCardVertical>
                </Container>
              );
            })}
          </Container>
        ) : (
          <CenteredContainer sx={{ maxHeight: "CALC(100vh - 133px)", height: "944px" }} maxWidth={false}>
            <LoadingSpinner />
          </CenteredContainer>
        )}
      </Container>
      <Container sx={{ display: "none" }} disableGutters>
        <CenteredContainer sx={{ marginBottom: "20px", flexDirection: "column" }}>
          <Typography variant="h4">
            <Trans i18nKey="simCardsTitle" components={{ b: <b /> }} />
          </Typography>
          <Typography variant="h6">{t("simCardsSubTitle")}</Typography>
        </CenteredContainer>
        <CenteredContainer sx={{ flexWrap: "wrap" }}>
          {operators.slice(0, -3).map((operator) => (
            <SimCard
              key={operator.name}
              name={operator.name}
              onClick={() => openModal(operator.name, operator.logo_bg_color)}
              color={operator.logo_bg_color}
              className="rotateAnimation"
              sx={{
                flex: { xs: "1 1 33%", md: "unset" },
              }}
            />
          ))}
        </CenteredContainer>
      </Container>
    </Container>
  );
}

export default forwardRef(TopPackages);
