import { FormControl, Select, MenuItem, Typography } from "@mui/material";
import { US, IL, AE, RU } from "country-flag-icons/react/3x2";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSharedContext } from "../../SharedContext";
import Navigate from "../Common/Navigate";

export default function LanguagePicker() {
  const { setIsLoading, setIsRTL, i18n } = useSharedContext();
  const location = useLocation();
  const [currPathName, setCurrPathName] = useState(location.pathname);
  const navigate = useNavigate();

  const languages = [
    { name: "עברית", value: "he", flag: IL, title: "Israel" },
    { name: "العربية", value: "ar", flag: AE, title: "United Arab Emirates" },
    { name: "Русский", value: "ru", flag: RU, title: "Russia" },
    { name: "English", value: "en", flag: US, title: "United States" },
  ];

  const handleMenuSelection = (lang) => {
    navigate(currPathName.replace(`/${i18n.language}`, `/${lang}`));
  };

  useEffect(() => {
    setCurrPathName(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    i18n.on("languageChanged", () => {
      setIsLoading(true);
      localStorage.setItem("zolsim_language", i18n.language);
      if (i18n.language === "he" || i18n.language === "ar") setIsRTL(true);
      else setIsRTL(false);
    });
  }, []);

  return (
    <FormControl sx={{ marginX: { xs: "14px", sm: "24px" } }}>
      <Select
        sx={{
          border: "1px solid",
          borderColor: grey[300],
          "&:hover": {
            bgcolor: grey[300],
          },
          "& .MuiSvgIcon-root": {
            color: grey[300],
          },
          "&:hover .MuiSvgIcon-root": {
            color: "var(--navbar-color)",
          },
          "& .MuiSelect-select": {
            padding: "6px 0px 0px 8px",
          },
          borderRadius: "5px",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
        }}
        value={i18n.language}
        inputProps={{ "aria-label": "Without label" }}
        displayEmpty
      >
        {languages.map((lang) => (
          <MenuItem
            key={lang.value}
            className="languageMenuItem"
            value={lang.value}
            onClick={() => handleMenuSelection(lang.value)}
          >
            <Typography>{lang.name}</Typography>
            <lang.flag title={lang.title} style={{ width: "25px" }} />
            <Navigate to={currPathName.replace(`/${i18n.language}`, `/${lang.value}`)}></Navigate>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
