import { Typography } from "@mui/material";
import { useState } from "react";
import { useSharedContext } from "../../SharedContext";
import CloseableModal from "./ClosableModal";

export default function LimitedSalePrices() {
  const localStorage_flag = localStorage.getItem("zolsim_limitedSalesPricesModalIsShown");
  const isOpen = localStorage_flag != null ? localStorage_flag != "false" : true;
  const [open, setOpen] = useState(isOpen);
  const { t } = useSharedContext();

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("zolsim_limitedSalesPricesModalIsShown", "false");
  };

  return (
    <CloseableModal open={open} setOpen={handleClose}>
      <Typography variant="h4" align="center" sx={{ fontWeight: "bold" }}>
        {t("limitedSalePrices_modal_title")}🔥
      </Typography>
      <Typography variant="h6" align="center">
        {t("limitedSalePrices_modal_body")}
      </Typography>
    </CloseableModal>
  );
}
