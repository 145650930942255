import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SharedContext = createContext();

export const useSharedContext = () => {
  return useContext(SharedContext);
};

export const SharedContextProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isRTL, setIsRTL] = useState(i18n.language === "he" || i18n.language === "ar");
  const goTo = useNavigate();
  const [openCartModal, setOpenCartModal] = useState(false);
  const [cartItems, setCartItems] = useState(JSON.parse(localStorage.getItem("zolsim_cartItems") || "[]"));

  const operators = [
    { name: "partner", title_color: "var(--partner-color)", logo_bg_color: "#2BD6C4", maxWidth: "85px" },
    { name: "012mobile", title_color: "#ffd400", logo_bg_color: "#232323", maxWidth: "72px" },
    { name: "pelephone", title_color: "#2c68ae", logo_bg_color: "#2C67AE", maxWidth: "67px" },
    { name: "cellcom", title_color: "#9530ff", logo_bg_color: "#9731FF", maxWidth: "90px" },
    { name: "hotmobile", title_color: "#de160a", logo_bg_color: "#010101", maxWidth: "67px" },
    { name: "019mobile", title_color: "black", logo_bg_color: "#000000", maxWidth: "100px" },
    { name: "golantelecom", title_color: "#ec1b30", logo_bg_color: "#C90013", maxWidth: "100px" },
    { name: "ramilevy", title_color: "#ff4bb2", logo_bg_color: "#E6007F", maxWidth: "100px" },
    { name: "skycall" },
  ];

  const navigate = (route, options = {}) => {
    const newRoute = route.replace(":lang", i18n.language);
    goTo(newRoute, options);
  };

  useEffect(() => {
    localStorage.setItem("zolsim_cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  return (
    <SharedContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isRTL,
        setIsRTL,
        t,
        i18n,
        navigate,
        operators,
        openCartModal,
        setOpenCartModal,
        cartItems,
        setCartItems,
      }}
    >
      {children}
    </SharedContext.Provider>
  );
};
