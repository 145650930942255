import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { useSharedContext } from "./SharedContext";

export default function WithHTMLHeaders({ component, titleKey }) {
  const { pathname } = useLocation();
  const { operator_name } = useParams();
  const { t } = useSharedContext();
  const title =
    operator_name == null
      ? t(`titles.${titleKey}`)
      : t(`titles.${titleKey}`, { operator_sim: t(`${operator_name}_sim`) });

  return (
    <>
      <Helmet>
        <link rel="alternate" href={`https://zolsim.co.il/ar${pathname.slice(3)}`} hreflang="ar" />
        <link rel="alternate" href={`https://zolsim.co.il/ru${pathname.slice(3)}`} hreflang="ru" />
        <link rel="alternate" href={`https://zolsim.co.il/en${pathname.slice(3)}`} hreflang="en" />
        <link rel="alternate" href={`https://zolsim.co.il/he${pathname.slice(3)}`} hreflang="x-default" />
        <title>{title}</title>
      </Helmet>
      {component}
    </>
  );
}
