import ErrorIcon from "@mui/icons-material/Error";
import { Typography } from "@mui/material";
import { useSharedContext } from "../../SharedContext";
import ROUTES from "../../routes";
import Navigate from "../Common/Navigate";

export default function CheckoutFailed() {
  const { t, isRTL } = useSharedContext();

  return (
    <>
      <ErrorIcon sx={{ fontSize: "7rem", color: "var(--red-color)" }} />
      <Typography align="center" sx={{ fontSize: "2.25rem" }}>
        {t("errorInTransaction")}
      </Typography>
      <Typography align={"center"} sx={{ fontSize: "1.25rem" }}>
        {t("tryAgainOrContact")}
        <br />
        <Navigate to={ROUTES.HOME} withDecoration>
          {t("backToHomePage")}
        </Navigate>
      </Typography>
    </>
  );
}
