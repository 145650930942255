import { Container, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSharedContext } from "../../SharedContext";
import { Trans } from "react-i18next";

export default function Item({ title, price, phone_number, operator_name, deleteCartItem }) {
  const { isRTL, t } = useSharedContext();

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        borderTop: "1px solid gray",
        py: "15px",
      }}
      disableGutters
    >
      <Container sx={{ display: "flex", justifyContent: "space-between" }} disableGutters>
        <Typography>{title}</Typography>
        <DeleteIcon
          sx={{ position: "relative", [isRTL ? "marginRight" : "marginLeft"]: "15px", cursor: "pointer" }}
          onClick={deleteCartItem}
        />
      </Container>
      {!phone_number && (
        <Typography>
          <Trans i18nKey="simCard" values={{ operator_sim: t(`${operator_name}_sim`) }} components={{ b: <b /> }} />
        </Typography>
      )}
      {phone_number && (
        <Typography sx={{ direction: "ltr", textAlign: isRTL ? "right" : "left" }}>
          {t("phoneNumber")}
          <br />
          {phone_number}
        </Typography>
      )}
      <Container sx={{ display: "flex", justifyContent: "space-between" }} disableGutters>
        <Typography sx={{ fontWeight: "bold" }}>{t("price")}</Typography>
        <Typography sx={{ fontWeight: "bold", margin: "1px 0px 0px 2px" }}>{price} ₪</Typography>
      </Container>
    </Container>
  );
}
