import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useSharedContext } from "../../SharedContext";
import ROUTES from "../../routes";

export default function CheckoutIframe({ checkoutUrl, order_id }) {
  const { navigate } = useSharedContext();

  useEffect(() => {
    const onHypCheckoutSuccess = (event) => {
      if (event.origin !== "https://zolsim.co.il") {
        return;
      }
      navigate(ROUTES.ORDER_CONFIRMATION.replace(":order_id", order_id), {
        state: { checkoutSuccessParams: event.data },
      });
    };
    window.addEventListener("message", onHypCheckoutSuccess);

    return () => {
      window.removeEventListener("message", onHypCheckoutSuccess);
    };
  }, []);

  return (
    <Box
      sx={{
        height: "1225px",
        width: "100%",
        maxWidth: "446px",
        border: 0,
      }}
      component="iframe"
      src={checkoutUrl}
    />
  );
}
