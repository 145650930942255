import { useRef } from "react";
import MainSection from "./MainSection";
import TopPackages from "./TopPackages";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import LimitedSalePrices from "../Common/LimitedSalePrices";

export default function HomePage() {
  const { t } = useTranslation();
  const topPackagesRef = useRef(null);

  return (
    <Container maxWidth={false} disableGutters>
      <MainSection ref={topPackagesRef} />
      <TopPackages ref={topPackagesRef} />
      <LimitedSalePrices />
    </Container>
  );
}
