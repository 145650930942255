import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import { useSharedContext } from "../../SharedContext";

export default function Header() {
  const navigate = useNavigate();
  const { t } = useSharedContext();
  return (
    <Container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }} maxWidth={false}>
      <Container
        sx={{ display: "flex", gap: "3px", alignItems: "center", width: "fit-content", m: 0 }}
        className="hyperLink"
        onClick={() => navigate(-1)}
        disableGutters
      >
        <ArrowBackIcon sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }} />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {t("back")}
        </Typography>
      </Container>
      <Container sx={{ display: "flex", width: "fit-content", m: 0, gap: "3px", alignItems: "center" }} disableGutters>
        <LockIcon sx={{ color: "#aaa", fontSize: { xs: "1.25rem", sm: "1.5rem" } }} />
        <Typography sx={{ whiteSpace: "nowrap", fontWeight: "bold", color: "var(--online-color)" }} variant="h6">
          {t("securePayment")}
        </Typography>
      </Container>
    </Container>
  );
}
