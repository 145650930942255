import CenteredContainer from "../Common/CenteredContainer";
import PageNotFound from "../Common/PageNotFound";
import TopUpSuccess from "./TopUpSuccess";
import TopUpFailed from "./TopUpFailed";
import CheckoutFailed from "./CheckoutFailed";
import UnknownFailure from "./UnknownFailure";
import { useEffect, useState } from "react";
import API from "../../API";
import { useLocation, useParams } from "react-router-dom";
import ProcessingRequestSpinner from "./ProcessingRequestSpinner";

export default function OrderConfirmation() {
  const location = useLocation();
  let checkoutSuccessParams = location.state?.checkoutSuccessParams;
  const { order_id } = useParams();
  const [Component, setComponent] = useState(() => ProcessingRequestSpinner);

  const shortPolling = async () => {
    const minute_later = Date.now() + 60000;
    let status = null;
    while (Date.now() < minute_later) {
      status = await API.getOrderStatus(order_id);
      if (status == null || status["errMsg"] != "inFulfillment") return status;
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    return status;
  };

  const startTopUp = async () => {
    let status;
    if (checkoutSuccessParams != null) {
      status = await API.checkoutSuccess(checkoutSuccessParams);
      checkoutSuccessParams = null;
    } else {
      status = await API.getOrderStatus(order_id);
    }
    if (status != null && status["errMsg"] == "inFulfillment") {
      status = await shortPolling();
    }
    if (status == null || ["unknownError", "inFulfillment"].includes(status["errMsg"])) {
      setComponent(() => UnknownFailure);
    } else if (status["success"]) {
      setComponent(() => () => (
        <TopUpSuccess order_id={status["order_id"]} alread_fulfilled={status["alreadyFulfilled"]} />
      ));
    } else if (["transactionFaild", "transactionNotVerified"].includes(status["errMsg"])) {
      setComponent(() => CheckoutFailed);
    } else if (status["errMsg"] == "topupFailed") {
      setComponent(() => TopUpFailed);
    } else if (["missingParams", "orderNotExist"].includes(status["errMsg"])) {
      setComponent(() => PageNotFound);
    } else {
      setComponent(() => UnknownFailure);
    }
  };

  useEffect(() => {
    if (order_id == null) setComponent(() => PageNotFound);
    else {
      startTopUp();
    }
  }, []);

  return (
    <CenteredContainer sx={{ height: "100vh", flexDirection: "column" }} maxWidth={false}>
      <Component />
    </CenteredContainer>
  );
}
