import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import API from "../../API";
import CenteredContainer from "../Common/CenteredContainer";
import CheckoutIframe from "./CheckoutIframe";
import Header from "./Header";
import LoadingSpinner from "../Common/LoadingSpinner";
import PageNotFound from "../Common/PageNotFound";
import ROUTES from "../../routes";
import { useSharedContext } from "../../SharedContext";

export default function Checkout() {
  const location = useLocation();
  const { order_id } = useParams();
  const [checkoutUrl, setCheckoutUrl] = useState(location.state?.checkoutUrl);
  const { navigate } = useSharedContext();

  useEffect(() => {
    if (checkoutUrl == null && order_id != null) {
      const fetchCheckoutUrl = async () => {
        const res = await API.getCheckoutPageUrl(order_id);
        if (res != null) {
          if (res["checkout_url"] != null) setCheckoutUrl(res["checkout_url"]);
          else navigate(ROUTES.ORDER_CONFIRMATION.replace(":order_id", order_id));
        }
      };
      fetchCheckoutUrl();
    }
  }, [checkoutUrl, order_id]);

  return (
    <>
      {order_id == null ? (
        <PageNotFound />
      ) : (
        <CenteredContainer
          sx={{ pt: checkoutUrl != null ? "80px" : 0, flexDirection: "column", minHeight: "100vh" }}
          maxWidth={false}
          disableGutters
        >
          {checkoutUrl == null ? (
            <CenteredContainer sx={{ flex: 1 }} maxWidth={false} disableGutters>
              <LoadingSpinner />
            </CenteredContainer>
          ) : (
            <>
              <Header />
              <CheckoutIframe checkoutUrl={checkoutUrl} order_id={order_id} />
            </>
          )}
        </CenteredContainer>
      )}
    </>
  );
}
